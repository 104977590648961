import { createAction } from "@ngrx/store";
import { props } from "@ngrx/store";
import {ValueProps} from "src/app/root/store/actions/props/value-props";
import {ChangePagesActionProps} from "src/app/operator/store/actions/props/operator/change-pages.action-props";

/**
 * Типы событий, связанные с просмотрщиком страниц.
 */
export enum PageViewerActionType {
    INIT = "[PageViewer] Init",
    CHANGE_CURRENT_PAGE = "[PageViewer] Change page",
    CHANGE_PAGES = "[PageViewer] Change pages",
    CHANGE_LOCKED = "[PageViewer] Change locked",
    CHANGE_POSITION = "[PageViewer] Change position",
    CHANGE_MODE = "[PageViewer] Change mode",
    CHANGE_SCALE = "[PageViewer] Change scale",
    CHANGE_CHAR_SIZE = "[PageViewer] Change char size",
    CHANGE_ROTATION_ANGLE = "[PageViewer] Change rotation angle",
    CHANGE_PAGE_TOP = "[PageViewer] Change page top",
    CHANGE_PAGE_LEFT = "[PageViewer] Change page left",
}

/**
 * Событие, требующее инициализации просмотрщика.
 */
const init = createAction(
    PageViewerActionType.INIT,
);

/**
 * Событие, требующее изменения текущей страницы.
 */
const changeCurrentPage = createAction(
    PageViewerActionType.CHANGE_CURRENT_PAGE,
    props<ValueProps<number>>(),
);

/**
 * Событие, требующее изменения URL-ов и номеров страниц, относящиеся к обрабатываемому документу.
 */
const changePages = createAction(
    PageViewerActionType.CHANGE_PAGES,
    props<ChangePagesActionProps>(),
);

/**
 * Событие, требующее изменения режима навигации по страницам.
 */
const changeLocked = createAction(
    PageViewerActionType.CHANGE_LOCKED,
    props<ValueProps<boolean>>(),
);

/**
 * Событие, требующее изменения положения панели просмотра страниц.
 */
const changePosition = createAction(
    PageViewerActionType.CHANGE_POSITION,
    props<ValueProps<string>>(),
);

/**
 * Событие, требующее изменения режима просмотра страниц.
 */
const changeMode = createAction(
    PageViewerActionType.CHANGE_MODE,
    props<ValueProps<string>>(),
);

/**
 * Событие, требующее изменения масштаба страницы.
 */
const changeScale = createAction(
    PageViewerActionType.CHANGE_SCALE,
    props<ValueProps<number>>(),
);

/**
 * Событие, требующее изменения размера шрифта для отображения текста страницы.
 */
const changeCharSize = createAction(
    PageViewerActionType.CHANGE_CHAR_SIZE,
    props<ValueProps<number>>(),
);


/**
 * Событие, требующее изменения угла поворота страницы.
 */
const changeRotationAngle = createAction(
    PageViewerActionType.CHANGE_ROTATION_ANGLE,
    props<ValueProps<number>>(),
);

/**
 * Событие, требующее изменения смещения изображения страницы по Y.
 */
const changePageTop = createAction(
    PageViewerActionType.CHANGE_PAGE_TOP,
    props<ValueProps<number>>(),
);

/**
 * Событие, требующее изменения смещения изображения страницы по X.
 */
const changePageLeft = createAction(
    PageViewerActionType.CHANGE_PAGE_LEFT,
    props<ValueProps<number>>(),
);

/**
 * Тип, объединяющий все события, связанные с просмотрщиком страниц.
 */
export type PageViewerAction =
    ReturnType<typeof init>
    | ReturnType<typeof changeCurrentPage>
    | ReturnType<typeof changePages>
    | ReturnType<typeof changeLocked>
    | ReturnType<typeof changePosition>
    | ReturnType<typeof changeMode>
    | ReturnType<typeof changeScale>
    | ReturnType<typeof changeCharSize>
    | ReturnType<typeof changeRotationAngle>
    | ReturnType<typeof changePageTop>
    | ReturnType<typeof changePageLeft>
    ;

/**
 * Все события, связанные с просмотрщиком страниц.
 */
export const pageViewerActions = {
    init: init,
    changeCurrentPage: changeCurrentPage,
    changePages: changePages,
    changeLocked: changeLocked,
    changePosition: changePosition,
    changeMode: changeMode,
    changeScale: changeScale,
    changeCharSize: changeCharSize,
    changeRotationAngle: changeRotationAngle,
    changePageTop: changePageTop,
    changePageLeft: changePageLeft,
};
