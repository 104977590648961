import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { ButtonWithDropdownMenuItem } from "src/app/common/models/button-with-dropdown-menu-item";

/**
 * Компонент кнопки с выпадающим списком.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "button-with-dropdown-menu",
    styleUrls: ["button-with-dropdown-menu.component.scss"],
    templateUrl: "./button-with-dropdown-menu.component.html"
})
export class ButtonWithDropdownMenuComponent {
    //region Inputs

    /**
     * Объект описания поведения и отображения кнопки для компоненты.
     */
    @Input()
    buttonItem: ButtonWithDropdownMenuItem;

    /**
     * Массив кнопок, которые будут использоваться в выпадающем списке.
     */
    @Input()
    dropdownItemList: ButtonWithDropdownMenuItem[];

    /**
     * Показать все элементы без скролла?
     */
    @Input()
    showAllNoScroll: boolean = false;

    //endregion
    //region Outputs

    /**
     * Исходящее событие - нажатие по кнопке или по элементу из выпадающего списка.
     */
    @Output()
    itemClick: EventEmitter<string> = new EventEmitter<string>();

    //endregion
    //region Public

    public itemClickHandler(buttonItem: ButtonWithDropdownMenuItem) {

        this.itemClick.emit(buttonItem.event);
    }

    //endregion
}