import { ActionReducerMap } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { customerEditReducer } from "src/app/operator/store/reducers/company-edit.reducer";
import { supplierEditReducer } from "src/app/operator/store/reducers/company-edit.reducer";
import { pageViewerReducer } from "src/app/operator/store/reducers/page-viewer.reducer";
import { PageViewerState } from "src/app/operator/store/states/page-viewer.state";
import { SpaceCounterpartiesState } from "../states/space-counterparties.state";

import { customerCreationReducer } from "./company-creation.reducer";
import { supplierCreationReducer } from "./company-creation.reducer";

import { CompanySubmissionState } from "src/app/operator/store/reducers/company-submission.state";
import { operatorActivityReducer } from "./operator-activity.reducer";
import { OperatorActivityState } from "./operator-activity.state";
import { operatorReducer } from "./operator.reducer";
import { OperatorState } from "./operator.state";
import { pageSplitterReducer } from "./page-splitter.reducer";
import { PageSplitterState } from "./page-splitter.state";
import { spaceCounterpartiesReducer } from "./space-counterparties.reducer";

export { OperatorState } from "./operator.state";
export { OperatorActivityState } from "./operator-activity.state";
export { PageSplitterState } from "./page-splitter.state";

/**
 * Состояние очереди обработки документов.
 */
export interface QueueState {

    /**
     * Состояние работы оператора.
     */
    operatorState: OperatorState;

    /**
     * Состояние просмотрщика страниц.
     */
    pageViewerState: PageViewerState;

    /**
     * Состояние лога активности оператора.
     */
    operatorActivityState: OperatorActivityState;

    /**
     * Состояние создания компании поставщика.
     */
    supplierCreationState: CompanySubmissionState;

    /**
     * Состояние редактирования компании поставщика.
     */
    supplierEditState: CompanySubmissionState;

    /**
     * Состояние создания компании покупателя.
     */
    customerCreationState: CompanySubmissionState;

    /**
     * Состояние редактирования компании покупателя.
     */
    customerEditState: CompanySubmissionState;

    /**
     * Состояние блоков выбора контрагентов обрабатываемого документа.
     */
    spaceCounterpartiesState: SpaceCounterpartiesState;

    /**
     * Состояние интерфейса расклейки страниц.
     */
    pageSplitterState: PageSplitterState;
}

/**
 * Обратчики каждой части состояния очереди обработки документов.
 */
export const reducers: ActionReducerMap<QueueState> = {

    /**
     * Обработчик состояния работы оператора.
     */
    operatorState: operatorReducer,

    /**
     * Обработчик состояния просмотрщика страниц.
     */
    pageViewerState: pageViewerReducer,

    /**
     * Обработчик состояния лога активности оператора.
     */
    operatorActivityState: operatorActivityReducer,

    /**
     * Обработчик состояния создания поставщика.
     */
    supplierCreationState: supplierCreationReducer,

    /**
     * Обработчик состояния редактирования поставщика
     */
    supplierEditState: supplierEditReducer,

    /**
     * Обработчик состояния покупателя.
     */
    customerCreationState: customerCreationReducer,

    /**
     * Обработчик состояния редактирования покупателя
     */
    customerEditState: customerEditReducer,

    /**
     * Логика обработчика событий, связанных со списком уникальных контрагентов, привязанных к документам в заданном
     * пространстве документов.
     */
    spaceCounterpartiesState: spaceCounterpartiesReducer,

    /**
     * Обработчик состояния интерфейса расклейки страниц.
     */
    pageSplitterState: pageSplitterReducer,
};

/**
 * Селектор состояния очереди обработки документов.
 */
export const queueStateSelector = createFeatureSelector<QueueState>("queue");

