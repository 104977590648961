import { PageViewerActionType } from "src/app/operator/store/actions/page-viewer.action";
import { PageViewerAction } from "src/app/operator/store/actions/page-viewer.action";
import { PageViewerState } from "src/app/operator/store/states/page-viewer.state";

/**
 * Начальное состояние просмотрщика страниц.
 */
const initialState: PageViewerState = {

    /**
     * Список URL'ов страниц.
     */
    pageUrls: [],

    /**
     * Индекс текущей отображаемой страницы.
     */
    currentPage: 1,

    /**
     * Номера страниц, относящиеся к обрабатываемому документу.
     */
    pages: [],

    /**
     * Масштаб просмотра.
     */
    scale: 0.4,

    /**
     * Размер для отображения текста страницы.
     */
    charSize: 28,

    /**
     * Угол поворота изображения страницы (0, 90, 180, 270).
     */
    rotationAngle: 0,

    /**
     * Смещение изображения страницы влево. Координата смещения X.
     */
    pageLeft: 0,

    /**
     * Смещение изображения страницы вверх. Координата смещения Y.
     */
    pageTop: 0,

    /**
     * Флаг, который определяет то, что навигация может осуществляться только по страницам, которые относятся
     * к обрабатываемому документу.
     */
    locked: true,

    /**
     * Положение панели.
     */
    position: "r",

    /**
     * Режим просмотра.
     */
    mode: "image",
};

/**
 * Обработчик событий, связанных с просмотрщиком страниц.
 *
 * @param state Состояние работы просмотрщика страниц.
 * @param action Событие произошедшее в системе.
 */
export function pageViewerReducer(
    state: PageViewerState = initialState,
    action: PageViewerAction
): PageViewerState {

    let result = state;

    switch (action.type) {

        case PageViewerActionType.INIT: {

            result = initialState;
            break;
        }
        case PageViewerActionType.CHANGE_CURRENT_PAGE: {

            result = {
                ...state,
                currentPage: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_PAGES: {

            result = {
                ...state,
                pages: action.pages,
                pageUrls: action.pageUrls,
            };
            break;
        }
        case PageViewerActionType.CHANGE_LOCKED: {

            result = {
                ...state,
                locked: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_POSITION: {

            result = {
                ...state,
                position: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_MODE: {

            result = {
                ...state,
                mode: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_SCALE: {

            result = {
                ...state,
                scale: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_CHAR_SIZE: {

            result = {
                ...state,
                charSize: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_ROTATION_ANGLE: {

            result = {
                ...state,
                rotationAngle: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_PAGE_TOP: {

            result = {
                ...state,
                pageTop: action.value,
            };
            break;
        }
        case PageViewerActionType.CHANGE_PAGE_LEFT: {

            result = {
                ...state,
                pageLeft: action.value,
            };
            break;
        }
    }

    return result;
}
