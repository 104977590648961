var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PageViewerActionType } from "src/app/operator/store/actions/page-viewer.action";
/**
 * Начальное состояние просмотрщика страниц.
 */
var initialState = {
    /**
     * Список URL'ов страниц.
     */
    pageUrls: [],
    /**
     * Индекс текущей отображаемой страницы.
     */
    currentPage: 1,
    /**
     * Номера страниц, относящиеся к обрабатываемому документу.
     */
    pages: [],
    /**
     * Масштаб просмотра.
     */
    scale: 0.4,
    /**
     * Размер для отображения текста страницы.
     */
    charSize: 28,
    /**
     * Угол поворота изображения страницы (0, 90, 180, 270).
     */
    rotationAngle: 0,
    /**
     * Смещение изображения страницы влево. Координата смещения X.
     */
    pageLeft: 0,
    /**
     * Смещение изображения страницы вверх. Координата смещения Y.
     */
    pageTop: 0,
    /**
     * Флаг, который определяет то, что навигация может осуществляться только по страницам, которые относятся
     * к обрабатываемому документу.
     */
    locked: true,
    /**
     * Положение панели.
     */
    position: "r",
    /**
     * Режим просмотра.
     */
    mode: "image",
};
/**
 * Обработчик событий, связанных с просмотрщиком страниц.
 *
 * @param state Состояние работы просмотрщика страниц.
 * @param action Событие произошедшее в системе.
 */
export function pageViewerReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case PageViewerActionType.INIT: {
            result = initialState;
            break;
        }
        case PageViewerActionType.CHANGE_CURRENT_PAGE: {
            result = __assign({}, state, { currentPage: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_PAGES: {
            result = __assign({}, state, { pages: action.pages, pageUrls: action.pageUrls });
            break;
        }
        case PageViewerActionType.CHANGE_LOCKED: {
            result = __assign({}, state, { locked: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_POSITION: {
            result = __assign({}, state, { position: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_MODE: {
            result = __assign({}, state, { mode: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_SCALE: {
            result = __assign({}, state, { scale: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_CHAR_SIZE: {
            result = __assign({}, state, { charSize: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_ROTATION_ANGLE: {
            result = __assign({}, state, { rotationAngle: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_PAGE_TOP: {
            result = __assign({}, state, { pageTop: action.value });
            break;
        }
        case PageViewerActionType.CHANGE_PAGE_LEFT: {
            result = __assign({}, state, { pageLeft: action.value });
            break;
        }
    }
    return result;
}
