import { OnDestroy } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import { Component } from "@angular/core";
import { ElementRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { HostListener } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { SafeStyle } from "@angular/platform-browser";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { skip } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";

/**
 * Обобщённый компонент для просмотра заданного списка страниц.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "pages-viewer",
    templateUrl: "./pages-viewer.component.html",
    styleUrls: ["./pages-viewer.component.scss"]
})
export class PagesViewerComponent implements OnDestroy {
    //region Constant

    /**
     * Минимальный масштаб изображения страницы.
     *
     * Добавлена 1 сотая, чтобы избежать случая, когда масштаб станет 0.1 с какой-то очень маленькой делтой и будет
     * больше, чем 0.1.
     */
    static readonly MIN_SCALE: number = 0.11;

    /**
     * Максимальный масштаб изображения страницы.
     */
    static readonly MAX_SCALE: number = 4;

    /**
     * Шаг увеличения/уменьшения масштаба изображения.
     */
    static readonly SCALE_DELTA: number = 0.1;

    /**
     * Увеличенный шаг увеличения/уменьшения масштаба изображения, когда масштаб стал большим.
     */
    static readonly BIG_SCALE_DELTA: number = 0.5;

    //endregion
    //region Inputs

    /**
     * Входящие данные - масштаб изображения страницы.
     */
    @Input()
    set scale(value: number) {

        if (this._scale$.value !== value) {

            this._scale$.next(value);
        }
    }

    /**
     * Входящие данные - угол поворота изображения страницы.
     */
    @Input()
    set rotateAngle(value: number) {

        if (this._rotateAngle !== value) {

            this._rotateAngle = value;
            this._correctPositionAfterRotate(value);
        }
    }

    /**
     * Входящие данные - смещение изображения страницы влево.
     */
    @Input()
    set pageLeft(value: number) {

        if (this._pageLeft$.value !== value) {

            this._pageLeft$.next(value);
        }
    }

    /**
     * Входящие данные - смещение изображения страницы вверх.
     */
    @Input()
    set pageTop(value: number) {

        if (this._pageTop$.value !== value) {

            this._pageTop$.next(value);
        }
    }

    /**
     * Входящие данные - список URL-ов всех страниц задачи на распознавание.
     */
    @Input()
    pageUrls: string[] = [];

    /**
     * Входящие данные - индекс текущей отображаемой страницы.
     */
    @Input()
    set currentPage(value: number) {

        if (value >= 1 && (!this.pageNumbers || value <= this.pageNumbers.length)) {

            this._currentPage = value;
            this.markPageAsViewed.emit(value);
        }
    }

    /**
     * Входящие данные - массив номеров страниц, определяющий какие страницы должны быть отображены и в каком
     * порядке.
     *
     * Данный массив не обязательно должен содержать все доступные страницы.
     *
     * Номера представлены в человекочитаемом формате, т.е. 1 соответствует первой странице.
     *
     * Например, [3, 1] означает, что нужно отобразить две страницы, первой страницей будет третья в рамках всей
     * задачи на распознавание. Второй страницей будет первая в рамках всей задачи на распознавание. При этом всего
     * в задаче на распознавание может быть 3 и более страниц.
     */
    @Input()
    pageNumbers: number[];

    /**
     * Входящие данные - массив номеров страниц, которые относятся к обрабатываемому документу.
     *
     * Номера представлены в человекочитаемом формате, т.е. 1 соответствует первой странице. Порядок в массиве
     * определяет порядок страниц в документе.
     *
     * Например, [5, 3] означает, что документ состоит из двух страниц. Первой страницей документа является пятая
     * страница в рамках всей задачи на распознавание. Второй страницей документа является третья страница в рамках
     * всей задачи на распознавание.
     */
    @Input()
    documentPageNumbers: number[];

    /**
     * Входящие данные - показать интерфейс вообще.
     */
    @Input()
    showInterface: boolean = true;

    /**
     * Входящие данные - показать кнопку поворта.
     */
    @Input()
    showRotate: boolean = true;

    /**
     * Входящие данные - нужно ли корректировать координаты после загрузки изображения.
     */
    @Input()
    fixPositionAfterImageLoad: boolean;

    //endregion
    //region Outputs

    /**
     * Исходящее событие - изменение текущей страницы.
     */
    @Output()
    currentPageChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - считать страницу просмотренной.
     */
    @Output()
    markPageAsViewed: EventEmitter<number> = new EventEmitter<number>();

    /**
     * Исходящее событие - изменение масштаба изображения страницы.
     */
    @Output()
    scaleChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - изменение поворота изображения страницы.
     */
    @Output()
    rotateAngleChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - изменение смещения изображения страницы вверх.
     */
    @Output()
    pageTopChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - изменение смещения изображения страницы влево.
     */
    @Output()
    pageLeftChange: EventEmitter<number> = new EventEmitter();

    //endregion
    //region Fields

    //region Public

    /**
     * Флаг того, что выполняется загрузка изображения.
     */
    imageLoading: boolean = true;

    //endregion
    //region Private

    /**
     * Индекс текущей отображаемой страницы.
     */
    private _currentPage: number = 1;

    /**
     * Угол поворота изображения страницы (0, 90, 180, 270).
     */
    private _rotateAngle: number = 0;

    /**
     * Флаг выполнения перемещения изображения страницы внутри viewport'а.
     */
    private _pageMoving: boolean = false;

    /**
     * Ссылка на DOM-элемент, который является viewport'ом для изображения страницы.
     */
    @ViewChild("viewport")
    private _viewportRef: ElementRef<HTMLElement>;

    /**
     * Ссылка на DOM-элемент, который является изображением страницы.
     */
    @ViewChild("image")
    private _imageRef: ElementRef<HTMLImageElement>;

    /**
     * Сервис для управления запуском определения angular'ом изменений данных, произошедших в компоненте.
     */
    private _cd: ChangeDetectorRef;

    /**
     * Сервис для обхода механизма безопасности HTML/CSS/JS в Angular'е.
     */
    private _sanitizer: DomSanitizer;

    /**
     * Смещение изображения страницы влево. Координата смещения X.
     */
    private _pageLeft$: BehaviorSubject<number> = new BehaviorSubject(0);

    /**
     * Смещение изображения страницы вверх. Координата смещения Y.
     */
    private _pageTop$: BehaviorSubject<number> = new BehaviorSubject(0);

    /**
     * Масштаб просмотра.
     */
    private _scale$: BehaviorSubject<number> = new BehaviorSubject(0.4);

    /**
     * Объект глобальной отписки.
     */
    private _globalUnsubscribe$: Subject<void> = new Subject();

    //endregion

    //endregion
    //region Ctor

    /**
     * Конструктор обобщённого компонента для просмотра заданного списка страниц.
     *
     * @param cd Сервис для управления запуском определения angular'ом изменений данных, произошедших в компоненте.
     * @param sanitizer Сервис для обхода механизма безопасности HTML/CSS/JS в Angular'е.
     */
    constructor(cd: ChangeDetectorRef, sanitizer: DomSanitizer) {

        this._cd = cd;
        this._sanitizer = sanitizer;

        this._pageLeft$.pipe(
            skip(1),
            debounceTime(100),
            takeUntil(this._globalUnsubscribe$),
        )
            .subscribe((value) => this.pageLeftChange.emit(value));

        this._pageTop$.pipe(
            skip(1),
            debounceTime(100),
            takeUntil(this._globalUnsubscribe$),
        )
            .subscribe((value) => this.pageTopChange.emit(value));

        this._scale$.pipe(
            skip(1),
            debounceTime(100),
            takeUntil(this._globalUnsubscribe$),
        )
            .subscribe((value) => this.scaleChange.emit(value));
    }

    //endregion
    //region Hooks

    ngOnDestroy() {

        this._globalUnsubscribe$.next();
        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Getters and Setters

    /**
     * Масштаб просмотра.
     */
    get scale(): number {

        return this._scale$.value;
    }

    /**
     * Угол поворота изображения страницы.
     */
    get rotateAngle(): number {

        return this._rotateAngle;
    }

    /**
     * Смещение изображения страницы влево. Координата смещения X.
     */
    get pageLeft(): number {

        return this._pageLeft$.value;
    }

    /**
     * Смещение изображения страницы вверх. Координата смещения Y.
     */
    get pageTop(): number {

        return this._pageTop$.value;
    }

    /**
     * DOM-элемент, который является viewport'ом для изображения страницы.
     */
    get viewport(): HTMLElement {

        return (this._viewportRef ? this._viewportRef.nativeElement : null);
    }

    /**
     * Отступ viewport'а от левой границы страницы.
     */
    get viewportPageX(): number {

        return (this.viewport ? this.viewport.getBoundingClientRect().left : 0);
    }

    /**
     * Отступ viewport'а от верхней границы страницы.
     */
    get viewportPageY(): number {

        return (this.viewport ? this.viewport.getBoundingClientRect().top : 0);
    }

    /**
     * Ширина viewport'а страницы.
     */
    get viewportWidth(): number {

        return (this.viewport ? this.viewport.clientWidth : 0);
    }

    /**
     * Высота viewport'а страницы.
     */
    get viewportHeight(): number {

        return (this.viewport ? this.viewport.clientHeight : 0);
    }

    /**
     * DOM-элемент изображения текущей страницы.
     */
    get currentImage(): HTMLImageElement {

        return (this._imageRef ? this._imageRef.nativeElement : null);
    }

    /**
     * Ширина изображения текущей страницы с учётом масштаба.
     */
    get imageWidth(): number {

        return this.scale * (this.currentImage ? this.currentImage.clientWidth : 0);
    }

    /**
     * Реальная ширина изображения текущей страницы.
     */
    get imageRealWidth(): number {

        return (this.currentImage ? this.currentImage.clientWidth : 0);
    }

    /**
     * Высота изображения текущей страницы с учётом масштаба.
     */
    get imageHeight(): number {

        return this.scale * (this.currentImage ? this.currentImage.clientHeight : 0);
    }

    /**
     * Реальная высота изображения текущей страницы.
     */
    get imageRealHeight(): number {

        return (this.currentImage ? this.currentImage.clientHeight : 0);
    }

    /**
     * Минимальная координата смещения изображения по X (т.е. смещения влево).
     */
    get minLeft(): number {

        let minLeft: number;

        switch (this._rotateAngle) {

            case 0:
                minLeft = Math.min(this.viewportWidth - this.imageWidth, 0);
                break;

            case 90:
                minLeft = this.imageHeight - Math.max(this.imageHeight - this.viewportWidth, 0);
                break;

            case 180:
                minLeft = this.imageWidth - Math.max(this.imageWidth - this.viewportWidth, 0);
                break;

            case 270:
                minLeft = Math.min(this.viewportWidth - this.imageHeight, 0);
                break;
        }

        return minLeft;
    }

    /**
     * Максимальная координата смещения изображения по X (т.е. смещения вправо).
     */
    get maxLeft(): number {

        let maxLeft: number;

        switch (this._rotateAngle) {

            case 0:
                maxLeft = 0;
                break;

            case 90:
                maxLeft = this.imageHeight;
                break;

            case 180:
                maxLeft = this.imageWidth;
                break;

            case 270:
                maxLeft = 0;
                break;
        }

        return maxLeft;
    }

    /**
     * Минимальная координата смещения изображения по Y (т.е. смещения вверх).
     */
    get minTop(): number {

        let minTop: number;

        switch (this._rotateAngle) {

            case 0:
                minTop = Math.min(this.viewportHeight - this.imageHeight, 0);
                break;

            case 90:
                minTop = Math.min(this.viewportHeight - this.imageWidth, 0);
                break;

            case 180:
                minTop = this.imageHeight - Math.max(this.imageHeight - this.viewportHeight, 0);
                break;

            case 270:
                minTop = this.imageWidth - Math.max(this.imageWidth - this.viewportHeight, 0);
                break;
        }

        return minTop;
    }

    /**
     * Максимальная координата смещения изображения по Y (т.е. смещения вниз).
     */
    get maxTop(): number {

        let maxTop: number;

        switch (this._rotateAngle) {

            case 0:
                maxTop = 0;
                break;

            case 90:
                maxTop = 0;
                break;

            case 180:
                maxTop = this.imageHeight;
                break;

            case 270:
                maxTop = this.imageWidth;
                break;
        }

        return maxTop;
    }

    /**
     * Индекс текущей отображаемой страницы.
     */
    get currentPage(): number {

        return this._currentPage;
    }

    /**
     * Номер текущей отображаемой страницы.
     */
    get currentPageNumber(): number {

        return this.pageNumbers[this.currentPage - 1];
    }

    /**
     * Индекс текущей страницы в рамках документа.
     */
    get documentPageIndex(): number {

        const documentPageIndex: number = this.documentPageNumbers.indexOf(this.currentPageNumber) + 1;
        return (documentPageIndex || null);
    }

    /**
     * Номер текущей страницы в рамках документа.
     */
    get documentPageNumber(): number {

        let documentPageNumber: number = -1;

        const documentPageIndex: number = this.documentPageNumbers.indexOf(this.currentPageNumber);
        if (documentPageIndex !== -1) {

            documentPageNumber = this.documentPageNumbers[documentPageIndex];
        }

        return documentPageNumber;
    }

    /**
     * Текущая страница относится к документу?
     */
    get currentPageBelongsToDocument(): boolean {

        return (this.documentPageNumber !== -1);
    }

    /**
     * Отображаемые страницы по составу и порядку совпадают со страницами документа?
     */
    get pagesAlignedWithDocumentPages(): boolean {

        let result: boolean = (this.pageNumbers.length === this.documentPageNumbers.length);

        if (result) {

            for (let i: number = 0; i < this.pageNumbers.length && result; i++) {

                result = (this.pageNumbers[i] === this.documentPageNumbers[i]);
            }
        }

        return result;
    }

    /**
     * Шаг увеличения/уменьшения масштаба страницы.
     */
    get scaleDelta(): number {

        let result: number = PagesViewerComponent.SCALE_DELTA;

        if (this.scale >= 1.5) {

            result = PagesViewerComponent.BIG_SCALE_DELTA;
        }

        return result;
    }

    /**
     * URL изображения текущей страницы.
     */
    get currentPageUrl(): string {

        return this.pageUrls[this.currentPageNumber - 1];
    }

    /**
     * CSS-стиль изображения текущей страницы для её масштаба и поворота.
     *
     * Подобный стиль нельзя разместить прямо в шаблоне, т.к. Angular ругается на него как на небезопасный. Поэтому
     * требуется вручную указывать, что этот стиль безопасный.
     */
    get imageTransformStyle(): SafeStyle {

        return this._sanitizer.bypassSecurityTrustStyle(
            `scale(${this.scale}) rotate(${this.rotateAngle}deg)`
        );
    }

    //endregion
    //region Public

    /**
     * Выполняет поворот изображения страницы.
     */
    rotate(): void {

        this.rotateAngleChange.emit((this._rotateAngle + 90) % 360);
    }

    /**
     * Увеличивает масштаб изображения относительно заданной точки на странице.
     *
     * @param pagePointX Координата X точки.
     * @param pagePointY Координата Y точки.
     */
    increaseScale(pagePointX: number = -1, pagePointY: number = -1): void {

        if (this.scale < PagesViewerComponent.MAX_SCALE) {

            if (pagePointX !== -1) {

                const zoomPointX = pagePointX - this.viewportPageX - this.pageLeft;
                const scaledZoomPointX = zoomPointX * (1 + this.scaleDelta / this.scale);
                const viewportOffset = zoomPointX - scaledZoomPointX;
                this._changeX(viewportOffset);
            }

            if (pagePointY !== -1) {

                const zoomPointY = pagePointY - this.viewportPageY - this.pageTop;
                const scaledZoomPointY = zoomPointY * (1 + this.scaleDelta / this.scale);
                const viewportOffset = zoomPointY - scaledZoomPointY;
                this._changeY(viewportOffset);
            }

            this._scale$.next(this.scale + this.scaleDelta);
            this.correctImagePosition();
        }
    }

    /**
     * Уменьшает масштаб изображения относительно заданной точки на странице.
     *
     * @param pagePointX Координата X точки.
     * @param pagePointY Координата Y точки.
     */
    decreaseScale(pagePointX: number = -1, pagePointY: number = -1): void {

        if (this.scale > PagesViewerComponent.MIN_SCALE) {

            if (pagePointX !== -1) {

                const zoomPointX = pagePointX - this.viewportPageX - this.pageLeft;
                const scaledZoomPointX = zoomPointX * (1 - this.scaleDelta / this.scale);
                const viewportOffset = zoomPointX - scaledZoomPointX;
                this._changeX(viewportOffset);
            }

            if (pagePointY !== -1) {

                const zoomPointY = pagePointY - this.viewportPageY - this.pageTop;
                const scaledZoomPointY = zoomPointY * (1 - this.scaleDelta / this.scale);
                const viewportOffset = zoomPointY - scaledZoomPointY;
                this._changeY(viewportOffset);
            }

            this._scale$.next(this.scale - this.scaleDelta);
            this.correctImagePosition();
        }
    }

    /**
     * Показывает изображение следующей страницы.
     */
    nextPage(): void {

        if (this.currentPage < this.pageNumbers.length) {

            this.imageLoading = true;
            this.currentPageChange.emit(this.currentPage + 1);

            // При перемещении по страницам отображаем левый верхний угол.
            this._pageLeft$.next(this.maxLeft);
            this._pageTop$.next(this.maxTop);

            this.correctImagePosition();
        }
    }

    /**
     * Показывает изображение предыдущей страницы.
     */
    prevPage() {

        if (this.currentPage > 1) {

            this.imageLoading = true;
            this.currentPageChange.emit(this.currentPage - 1);

            // При перемещении по страницам отображаем левый верхний угол.
            this._pageLeft$.next(this.maxLeft);
            this._pageTop$.next(this.maxTop);

            this.correctImagePosition();
        }
    }

    /**
     * Защитная логика, чтобы позиция изображения страницы не вышла за границы viewport'а.
     */
    correctImagePosition(): void {

        setTimeout((): void => {

            this._changeX();
            this._changeY();
            this._cd.markForCheck();
        });
    }

    //endregion
    //region Events

    /**
     * Обработчик события выполнения scroll'а колесом прокрутки.
     *
     * В ответ на это событие выполняется увеличение или уменьшение масштаба относительно точки, в которой
     * расположен курсор мыши.
     *
     * @param event Событие scroll'а колесом прокрутки.
     */
    @HostListener("wheel", ["$event"])
    onWheelScroll(event: WheelEvent): void {

        event.preventDefault();

        const delta = event.deltaY || event["wheelDelta"];
        if (delta < 0) {

            this.increaseScale(event.pageX, event.pageY);
        }
        else {

            this.decreaseScale(event.pageX, event.pageY);
        }
    }

    /**
     * Обработка события нажатия левой кнопкой мыши на изображение страницы для начала её перемещения.
     */
    onMousedown(): void {

        this._pageMoving = true;
    }

    /**
     * Обработка события отпускания левой кнопки мыши.
     *
     * Останавливает перемещение изображение страницы.
     */
    @HostListener("document:mouseup")
    onMouseUp() {

        this._pageMoving = false;
    }

    /**
     * Обработка события перемещения курсора мыши.
     *
     * Если левая кнопка мыши зажата, то выполняется перемещение изображения страницы внутри viewport'а.
     */
    @HostListener("document:mousemove", ["$event"])
    onMouseMove($event: MouseEvent): void {

        if (this._pageMoving) {

            this._changeX($event.movementX);
            this._changeY($event.movementY);
        }
    }

    /**
     * Обработчик события успешной загрузки изображения страницы.
     */
    imageLoadHandler(): void {

        this.imageLoading = false;
        if (this.fixPositionAfterImageLoad) {

            this._correctPositionAfterRotate(this.rotateAngle);
        }
    }

    //endregion
    //region Private

    /**
     * Измение координаты X изображения страницы с ограничением по отрвыу от краев viewport'а.
     *
     * @param delta Смещение.
     */
    private _changeX(delta: number = 0) {

        const newX = this.pageLeft + delta;

        if (newX < this.minLeft) {

            this._pageLeft$.next(this.minLeft);
        }
        else if (newX > this.maxLeft) {

            this._pageLeft$.next(this.maxLeft);
        }
        else {

            this._pageLeft$.next(newX);
        }
    }

    /**
     * Измение координаты Y изображения страницы с ограничением по отрвыу от краев viewport'а.
     *
     * @param delta Смещение.
     */
    private _changeY(delta: number = 0) {

        const newY = this.pageTop + delta;

        if (newY < this.minTop) {

            this._pageTop$.next(this.minTop);
        }
        else if (newY > this.maxTop) {

            this._pageTop$.next(this.maxTop);
        }
        else {

            this._pageTop$.next(newY);
        }
    }

    /**
     * Корректировка позиции изображения после поворота.
     *
     * @param angle Угол поворота.
     */
    private _correctPositionAfterRotate(angle: number) {

        switch (angle) {

            case 0:
                this._pageTop$.next(0);
                this._pageLeft$.next(0);
                break;

            case 90:
                this._pageTop$.next(0);
                this._pageLeft$.next(this.imageHeight);
                break;

            case 180:
                this._pageTop$.next(this.imageHeight);
                this._pageLeft$.next(this.imageWidth);
                break;

            case 270:
                this._pageTop$.next(this.imageWidth);
                this._pageLeft$.next(0);
                break;
        }
    }

    //endregion
}
