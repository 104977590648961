<div class="errors-dialog">
    <h2 class="errors-dialog__header">{{ 'operator.checkDlg.header' | translate }}</h2>
    <div class="errors-dialog__groups">
        <div class="grid-container">
            <div *ngFor="let errorGroup of processingErrorsGroups">
                <h1
                    *ngIf="errorGroup.errors.length > 0"
                    class="mat-h1 errors-dialog__group-title"
                >
                    {{ errorGroup.titleKey | translate }}
                </h1>
                <div *ngFor="let error of errorGroup.errors; index as errorIndex">
                    <div
                        *ngIf="errorGroup.errors.length > 0"
                        class="errors-dialog__error-in-group"
                    >
                        <span class="errors-dialog__error-number">{{ errorIndex + 1 }}.</span>
                        <span>{{ error.messageKey | translate: error.messageArgs }}</span>
                        <span *ngIf="error.rowIndexes">:
                        <span *ngFor="let rowIndex of error.rowIndexes; let last = last; index as currentIndex">
                            <span *ngIf="isCurrentRowIndexFirstHidden(currentIndex, error.rowIndexes.length)">
                                ...,
                            </span>
                            <span *ngIf="isCurrentRowIndexVisible(currentIndex, error.rowIndexes.length)">
                                {{ rowIndex }}<!--
                                --><span *ngIf="!last">, </span><!--
                            --></span>
                        </span>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="errors-dialog__divider"></div>
    <div class="errors-dialog__buttons">
        <button class="errors-dialog__button"
            matDialogClose
            mat-raised-button
            color="primary"
        >
            {{ "operator.checkDlg.close" | translate }}
        </button>
        <button
            mat-raised-button
            color="warn"
            class="errors-dialog__button"
            [disabled]="isSavingLocked"
            (click)="okClickHandler()"
        >
            {{ "operator.checkDlg.ok" | translate }}
        </button>
    </div>
</div>
